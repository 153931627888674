import(/* webpackMode: "eager" */ "/app/app/(styles)/home.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/best-experience/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/float-buttons/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/footer-web/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/home-slider/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/i-want-it-section/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/mim-description/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/parallax/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/question-section/index.js");
